<template>
  <div id="wps"></div>
</template>

<script>
import WebOfficeSDK from "../../../assets/web-office-sdk-solution-v2.0.6/web-office-sdk-solution-v2.0.6.es";
import { submitUser } from "../../../api/common.js";

export default {
  data() {
    return {
      instance: null,
      app: "",
    };
  },
  mounted() {
		// let fileId = 'aa5464fff680443cb54e8701d020d1ed'
    // let userId = "2308226321734204"
    // this.openWps("AK20241225SBKTUI", "aa5464fff680443cb54e8701d020d1ed", "2308226321734204");
    //SX20240424NAZAZD 旧
    // SX20241211XRIMGT 新
    // 测试

    // 真实
    let fileId = this.$route.query.fileId
    let userId = this.$route.query.userId
    userId ? '' : userId = 'ReadOnlyUser'
    submitUser(fileId, userId).then(res => {
    	const { data } = res
    	if (data.code == 200) {
    		this.openWps('AK20241225SBKTUI', fileId, userId)
    	} else {
    		this.$message({
    			type: 'error',
    			message: '接口错误'
    		})
    	}
    })

    // this.light()
  },
  methods: {
    async openWps(appId, fileId, userId) {
      this.instance = WebOfficeSDK.init({
        officeType: WebOfficeSDK.OfficeType.Writer,
        appId: appId,
        fileId: fileId,
        mount: "#wps",
      });
      await this.instance.ready();
      const app = this.instance.Application;
      const revisions = await app.ActiveDocument.Revisions;
      revisions.RevisionsMode = 0;
      revisions.ShowRevisionsFrame = true;
      app.ActiveDocument.TrackRevisions = true;
      if (userId == "ReadOnlyUser") {
        await app.ActiveDocument.SetReadOnly({
          Value: true,
        });
      }
      const controls = await app.CommandBars("StartTab").Controls;
      // 添加定制元素：按钮
      const controlButton = await controls.Add(1);
      controlButton.Caption = "下载word"; // 设置标题
      // 设置 Icon
      controlButton.Picture =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAIpJREFUOE/VklEOgCAMQ8vJ1JOJJ1NvZmqATNysiV8u8Ye0T9qR8HGS8I8A+OVIpwBrMU7/AtjcNgLPOZuN43VAE8ULgKGIdwBzObsUGpVIEQ12+OdbmU9bsBDXTLpaIyGMIddYc4UPpovT9PUG8sF0gKbvAWz7zbDgs5cK8FpXIK45qxIVRG5BAg5/zxkRsqHtOQAAAABJRU5ErkJggg==";
      controlButton.Onclick = "download";

      // 添加点击事件处理函数
      controlButton.OnAction = async () => {
        try {
					console.log("下载下载");
					
          // 获取当前文档
          // const doc = app.ActiveDocument;
          // // 导出文档为Word格式
          // const result = await doc.SaveAs({
          //   // 使用原文件名,添加时间戳防止重名
          //   FileName: `文档.docx`,
          //   // FileFormat: WebOfficeSDK.FileFormat.Docx,
          // });
					let result={
						Url:`/wps_api/v3/3rd/files/fileDownload/${fileId}`
					}

          // 创建下载链接
          if (result.Url) {
            const link = document.createElement("a");
            link.href = result.Url;
            link.download = result.FileName || "document.docx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } catch (error) {
          console.error("下载文档失败:", error);
        }
      };

      controlButton.SetFocus(true); // 聚焦该元素
      // 添加按钮

      this.instance.ApiEvent.AddApiEventListener("tabSwitch", (data) => {
        console.log("tabSwitch: ", data);
      });

      // setTimeout(() => {
      // 	// 修订框隐藏
      // 	revisions.ShowRevisionsFrame = false
      // }, 2000)
    },
    download() {},
    async light() {
      await this.instance.ready();
      const app = this.instance.Application;
      console.log("+++++++++++", this.app);

      // const Execute = await app.ActiveDocument.Find.Execute('123')
      // console.log("Execute", Execute);

      //  Execute.forEach(item=>{
      // 	const font = await app.ActiveDocument.Range(52, 62).Font
      // 	font.Color ='#ffffff'
      // 	app.ActiveDocument.Range(52, 62).Text="您的预约申请已经通过"

      //  })
      // for (let i = 0; i <= Execute.length - 1; i++) {
      // const font = await app.ActiveDocument.Range(Execute[i].pos, Execute[i].pos+Execute[i].len).Font
      // font.Color ='#b03234'
      // app.ActiveDocument.Range(Execute[i].pos, Execute[i].pos+Execute[i].len).Text="baidu"

      // }

      // const font = await app.ActiveDocument.Range(52, 62).Font
      // font.Color ='#ffffff'
      // app.ActiveDocument.Range(52, 62).Text="您的预约申请已经通过"
      // const font = await app.ActiveDocument.Range(0, 20).Font
    },
    async example() {
      await instance.ready();

      const app = instance.Application;

      // 定制元素对象【开始 Tab】
      const controls = await app.CommandBars("StartTab").Controls;

      // 添加定制元素：按钮
      const controlButton = await controls.Add(1);
      controlButton.Caption = "按钮"; // 设置标题
      // 设置 Icon
      controlButton.Picture =
        "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjM0Q0NzU3IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik04LjIxMyAxM0g2LjhsNi42MzYtNi42MzYtNC4yNDMtNC4yNDMtNy4wNyA3LjA3MUw1LjkyOCAxM0g0LjUxNUwxLjA2IDkuNTQ2YS41LjUgMCAwIDEgMC0uNzA3TDguODM5IDEuMDZhLjUuNSAwIDAgMSAuNzA3IDBsNC45NSA0Ljk1YS41LjUgMCAwIDEgMCAuNzA3TDguMjEzIDEzeiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTQuNTM2IDYuMzY0bDQuOTUgNC45NS0uNzA3LjcwNy00Ljk1LTQuOTV6TTQuNTIxIDEzaDEwLjAzdjFINS40OTZ6Ii8+PC9nPjwvc3ZnPg==";
      controlButton.SetFocus(true); // 聚焦该元素
    },
  },
};
</script>

<style lang="less">
.wps {
  width: 50%;
}
</style>